import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Router } from '@angular/router';
import { updateGlobal } from '@windmillcode/wml-components-base';
(window as any).global = window;
// TODO for some reason window.ng is undefined in preview

declare global {

}
updateGlobal({propFrameworkName:"Angular"})
// window.WINDMILLCODE.framework.name ="Angular"




bootstrapApplication(AppComponent, appConfig)
  .then(ref => {
    const router = ref.injector.get(Router);
    const currentPath = window.location.pathname + window.location.search + window.location.hash;
    // router.navigateByUrl(currentPath,{ skipLocationChange: true,onSameUrlNavigation:'ignore' }).then(() => {
    // });
  })
  .catch((err) => {
    console.error(err)
  });
