import { Routes } from '@angular/router';
import { addPageTitlesToRoute, removeLeadingSlash } from '@core/utility/route-utils';
import { ENV } from '@env/environment';
import { threejsNavGuard } from './shared/guards/threejs-nav.guard';



export let routes: Routes = [

  {
    path: ENV.nav.urls.siteOffline,
    loadComponent: () =>
      import('./core/site-offline/site-offline.component').then(
        (m) => m.SiteOfflineComponent,
      ),
  },
  {
    path: ENV.nav.urls.notFound,
    loadComponent: () =>
      import('./core/not-found/not-found.component').then(
        (m) => m.NotFoundComponent,
      ),
  },
  {
    path: ENV.nav.urls.landing,
    loadComponent: () => import('./layouts/landing-zero-layout/landing-zero-layout.component').then(m => m.LandingZeroLayoutComponent),
    canMatch:[threejsNavGuard],
    children:[
      {
        path: '',
        loadComponent: () => import('./pages/home-zero-page/home-zero-page.component').then(m => m.HomeZeroPageComponent),
        children:[]
      },
      {
        path:ENV.nav.urls.legalDocPage,
        children:[
          {
            path: ENV.nav.urls.privacyPolicyPage,
            loadComponent: () =>
              import('./pages/legal-doc-page/legal-doc-page.component').then(
                (m) => m.LegalDocPageComponent,
              ),
          },
          {
            path: ENV.nav.urls.termsOfServicePage,
            loadComponent: () =>
              import('./pages/legal-doc-page/legal-doc-page.component').then(
                (m) => m.LegalDocPageComponent,
              ),
          },
        ]
      },
      {
        path: ENV.nav.urls.guide,
        loadComponent: () => import('./pages/guide-zero-page/guide-zero-page.component').then(m => m.GuideZeroPageComponent),
        children:[]
      },
      {
        path: ENV.nav.urls.contact,
        loadComponent: () => import('./pages/contact-zero-page/contact-zero-page.component').then(m => m.ContactZeroPageComponent),
        children:[]
      }


    ]
  },
  {
    path: '**',
    redirectTo:ENV.nav.urls.notFound
  },
];


if (['DEV', 'DOCKER_DEV'].includes(ENV.type)) {
  let scratchpadRoute = {
    path: "scratchpad",
    loadComponent: () =>
      import('./pages/scratchpad/scratchpad.component').then(
        (m) => m.ScratchpadComponent,
      ),
  };
  routes.splice(1, 0, scratchpadRoute);
}


routes = removeLeadingSlash(routes)
routes= addPageTitlesToRoute(routes)

